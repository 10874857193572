import React from 'react';
import FormulaElement from '../FormulaElement';
import {View} from '@react-pdf/renderer';
import {IResultValues} from '~src/services/Calculation1Service';
import Spacer from '~src/components/pdf/Spacer';
import StatusMessage from '~src/components/pdf/StatusMessage';

interface IConclusionsProps {
  resultValues: IResultValues;
}

const Conclusions1: React.FC<IConclusionsProps> = ({resultValues}) => {
  return (
    <View>
      <View>
        <View style={{flexDirection: 'row'}}>
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'R'} fontStyle={'italic'} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion1.symbol} />
            <Spacer width={2} />
            <FormulaElement value={'R'} sub={'c'} fontStyle={'italic'} />
          </View>
          <Spacer width={5} />
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'('} />
            <FormulaElement value={`${resultValues.R} м²°С/Вт`} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion1.symbol} />
            <Spacer width={2} />
            <FormulaElement value={`${resultValues.r_c} м²°С/Вт`} />
            <FormulaElement value={')'} />
          </View>
        </View>
        <Spacer height={2} />
        <View>
          <StatusMessage type={resultValues.conclusion1.type} description={resultValues.conclusion1.description} />
        </View>
      </View>
      <Spacer height={5} />
      <View>
        <View style={{flexDirection: 'row'}}>
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'R'} fontStyle={'italic'} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion2.symbol} />
            <Spacer width={2} />
            <FormulaElement value={'R'} sub={'э'} fontStyle={'italic'} />
          </View>
          <Spacer width={5} />
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'('} />
            <FormulaElement value={`${resultValues.R} м²°С/Вт`} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion2.symbol} />
            <Spacer width={2} />
            <FormulaElement value={`${resultValues.r_e} м²°С/Вт`} />
            <FormulaElement value={')'} />
          </View>
        </View>
        <Spacer height={2} />
        <View>
          <StatusMessage type={resultValues.conclusion2.type} description={resultValues.conclusion2.description} />
        </View>
      </View>
    </View>
  );
};

export default Conclusions1;
