import React from 'react';
import {StyleSheet, View} from '@react-pdf/renderer';
import {customColors} from '~src/theme/colors';

interface IZoneProps {}

const styles = StyleSheet.create({
  View: {
    width: 10,
    height: 10,
    color: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: customColors.blue400,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: customColors.blue1000,
  },
});

const Zone: React.FC<IZoneProps> = () => {
  return <View style={styles.View} />;
};

export default Zone;
