import React from 'react';
import {StyleSheet, Text} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  Title1: {
    fontSize: 20,
    paddingBottom: 30,
  },
  Title2: {
    fontSize: 15,
    paddingBottom: 20,
  },
  Title3: {
    fontSize: 12,
    paddingBottom: 15,
  },
});

interface ITitleProps {
  level: 1 | 2 | 3;
}

const Title: React.FC<ITitleProps> = props => {
  let style = styles.Title1;
  switch (props.level) {
    case 2:
      style = styles.Title2;
      break;
    case 3:
      style = styles.Title2;
      break;
  }
  return <Text style={style}>{props.children}</Text>;
};

export default Title;
