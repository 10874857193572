import React from 'react';
import FormulaElement from '../FormulaElement';
import {View} from '@react-pdf/renderer';
import {IResultValues} from '~src/services/Calculation1Service';
import Spacer from '~src/components/pdf/Spacer';
import StatusMessage from '~src/components/pdf/StatusMessage';

interface IConclusionsProps {
  resultValues: IResultValues;
}

const Conclusions2: React.FC<IConclusionsProps> = ({resultValues}) => {
  return (
    <View>
      <View>
        <View style={{flexDirection: 'row'}}>
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'R'} sub={'ni'} fontStyle={'italic'} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion5!.symbol} />
            <Spacer width={2} />
            <FormulaElement value={'R'} sup={'тр'} sub={'n'} fontStyle={'italic'} />
          </View>
          <Spacer width={5} />
          <View style={{flexDirection: 'row'}}>
            <FormulaElement value={'('} />
            <FormulaElement value={`${resultValues.R_ni} м²·ч·Па/мг`} />
            <Spacer width={2} />
            <FormulaElement value={resultValues.conclusion5!.symbol} />
            <Spacer width={2} />
            <FormulaElement value={`${Math.max(resultValues.R_tr_n1!, resultValues.R_tr_n2!)} м²·ч·Па/мг`} />
            <FormulaElement value={')'} />
          </View>
        </View>
        <Spacer height={2} />
        <View>
          <StatusMessage type={resultValues.conclusion5!.type} description={resultValues.conclusion5!.description} />
        </View>
      </View>
    </View>
  );
};

export default Conclusions2;
