import React from 'react';
import {Text, View} from '@react-pdf/renderer';

export interface IFormulaItem {
  value: string | IFormulaItem[][];
  fontStyle?: string;
  sub?: string;
  sup?: string;
}

interface IFormulaElementProps extends IFormulaItem {}

const Formula: React.FC<IFormulaElementProps> = ({sup = '', sub = '', fontStyle, value}) => {
  return (
    <View style={{display: 'flex', fontFamily: 'Roboto', flexDirection: 'row'}}>
      <View>
        <Text>{value}</Text>
      </View>
      <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 6}}>{sup || ''}</Text>
        <Text style={{fontSize: 6}}>{sub || ''}</Text>
      </View>
    </View>
  );
};

export default Formula;
