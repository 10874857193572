import React from 'react';
import {View} from '@react-pdf/renderer';

interface ISpacerProps {
  height?: number;
  width?: number;
}

const Spacer: React.FC<ISpacerProps> = props => {
  return (
    <View
      style={{
        height: props.height ? props.height * 2 : 0,
        width: props.width ? props.width * 2 : 0,
      }}
    />
  );
};

export default Spacer;
