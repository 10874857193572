import React, {useContext} from 'react';
import {Document, Page, Image, StyleSheet, View, Text} from '@react-pdf/renderer';
import {globalStyles} from '~src/components/pdf/style';
import Content from '~src/components/pdf/Content';
import PageView from '~src/components/pdf/PageView';
import Title from '~src/components/pdf/Title';
import {LocaleContext} from '~src/context';
import {IUserValuesAtCalculation3} from '~src/store/Calculations';
import Table from '~src/components/pdf/Table';
import Spacer from '~src/components/pdf/Spacer';
import {IResultValues} from '~src/services/Calculation3Service';
import Calc2Footer from '~src/components/pdf/Calc2Footer';

interface IResult2Props {
  userValues: IUserValuesAtCalculation3;
  resultValues: IResultValues;
}

const Result2: React.FC<IResult2Props> = props => {
  const {
    pages: {
      calculation2: {balkResult},
    },
  } = useContext(LocaleContext);
  if (!props.resultValues) return null;

  const passResultValuesToDataSource = (dataSource: any[]) => {
    return dataSource.map(item => {
      if (props.resultValues[item.name] !== 0 || props.resultValues[item.name] !== null) {
        return {...item, name: props.resultValues[item.name]};
      } else {
        return {...item, name: null};
      }
    });
  };

  const styles = StyleSheet.create({
    bearingWallImage: {
      width: 250,
      zIndex: 3,
      position: 'absolute',
    },
    imageBox: {
      height: 200,
      width: 200,
      marginRight: 50,
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    imageDescription: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginTop: 20,
      marginRight: 50,
    },
    imageDescriptionText: {
      fontSize: 15,
      fontWeight: 600,
      width: 150,
    },
  });

  return (
    <Document title={'PDF отчёт'}>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{balkResult.title}</Title>
            <Title level={2}>{balkResult.sectionsTitle}</Title>
            <View style={styles.imageContainer}>
              <View style={styles.imageBox}>
                <Image style={styles.bearingWallImage} src={props.userValues.bearingWallImage} />
              </View>
              <View style={styles.imageDescription}>
                <Text style={styles.imageDescriptionText} wrap>
                  {props.userValues.bearingWalls[0].balkType +
                    ', ' +
                    props.userValues.bearingWalls[0].balkWidth +
                    'x' +
                    props.userValues.bearingWalls[0].balkHeight +
                    ' мм'}
                </Text>
              </View>
            </View>
            <Table
              columns={balkResult.section1.columns}
              dataSource={passResultValuesToDataSource(balkResult.section1.rows)}
            />
            <Spacer height={15} />
          </Content>
          <Calc2Footer />
        </PageView>
      </Page>
    </Document>
  );
};

export default Result2;
