import React, {useContext} from 'react';
import {Document, Image, Page, View} from '@react-pdf/renderer';
import {globalStyles} from '~src/components/pdf/style';
import Footer from '~src/components/pdf/Footer';
import Content from '~src/components/pdf/Content';
import PageView from '~src/components/pdf/PageView';
import Title from '~src/components/pdf/Title';
import {LocaleContext} from '~src/context';
import UserValues from '~src/components/pdf/Calculation1/UserValues';
import {IUserValuesAtCalculation1, IUserValuesAtCalculation2, IUserValuesAtCalculation3} from '~src/store/Calculations';
import Spacer from '~src/components/pdf/Spacer';
import Legend from '~src/components/pdf/Legend';
import Table from '~src/components/pdf/Table';
import {IResultValues} from '~src/services/Calculation1Service';
import Literature from '~src/components/pdf/Literature';
import {createDataSourceResult3} from '~src/utils/calculations';

interface Props {
  userValues: IUserValuesAtCalculation1 | IUserValuesAtCalculation2 | IUserValuesAtCalculation3;
  resultValues: IResultValues;
}

const Result3: React.FC<Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);

  let dataSource = createDataSourceResult3(props.resultValues, result);

  const conclusionDataSource = result.result3TableGeneration.conclusionParams.dataSource.map(row => ({
    ...row,
    value: `${props.resultValues[row.propName]} ${row.unit}`,
  }));

  if (!props.resultValues || !props.userValues) return null;
  return (
    <Document title={'PDF отчёт'}>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{result.title}</Title>
            <View>
              <Image style={{width: 300}} src={props.resultValues.image} />
            </View>
            <Spacer height={10} />
            <View>
              <Legend resultType={2} />
            </View>
            <Spacer height={15} />
            <Title level={2}>{result.userValuesTitle}</Title>
            <UserValues userValues={props.userValues as IUserValuesAtCalculation1} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{result.title}</Title>
            <Title level={2}>{result.result3TableGeneration.heatLossParams.title}</Title>
            <View>
              <Table dataSource={dataSource} columns={result.result3TableGeneration.heatLossParams.columns} />
            </View>
            <Spacer height={15} />
            <Title level={2}>{result.result3TableGeneration.conclusionParams.title}</Title>
            <View>
              <Table
                dataSource={conclusionDataSource}
                columns={result.result3TableGeneration.conclusionParams.columns}
              />
            </View>
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>{'Литература'}</Title>
            <Literature />
          </Content>
          <Footer />
        </PageView>
      </Page>
    </Document>
  );
};

export default Result3;
