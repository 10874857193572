import React from 'react';
import * as colors from '@ant-design/colors';
import {Text} from '@react-pdf/renderer';

interface IStatusMessageProps {
  type: 'success' | 'warning' | 'error';
  description: string;
}

const MessageColors = {
  success: colors.green.primary,
  warning: colors.orange.primary,
  error: colors.red.primary,
};

const StatusMessage: React.FC<IStatusMessageProps> = props => {
  return <Text style={{color: MessageColors[props.type], fontSize: 8}}>{props.description}</Text>;
};

export default StatusMessage;
