import React, {Fragment} from 'react';
import {Text, View} from '@react-pdf/renderer';
import FormulaElement from './FormulaElement';
import {formulas} from '~src/services/Calculation1Service';

export interface IFormulaItem {
  value: string | IFormulaItem[][];
  fontStyle?: string;
  sub?: string;
  sup?: string;
  type?: string;
}

interface IFormulaValue {
  name: string;
  value: IFormulaItem[];
  unit: string;
}

interface IFormulaProps {
  name:
    | 't_notr'
    | 'e_notr'
    | 'x_mu'
    | 'termResist'
    | 'z1'
    | 'z2'
    | 'z3'
    | 'to1'
    | 'to2'
    | 'to3'
    | 't1'
    | 't2'
    | 't3'
    | 'E1'
    | 'E2'
    | 'E3'
    | 'R_pn'
    | 'z_o'
    | 't_o'
    | 'E_o'
    | 'r_t'
    | 'r_e'
    | 'r_c'
    | 'r_usl'
    | 'r'
    | 'gsop'
    | 'f_i'
    | 'r_op'
    | 'R_ni'
    | 'E_b'
    | 'e_b'
    | 't_x'
    | 'E'
    | 'E_i'
    | 'E_0'
    | 't_0'
    | 'e_notr'
    | 'eta'
    | 'R_tr_n1'
    | 'R_tr_n2';
  value?: string | number;
}

const Formula: React.FC<IFormulaProps> = ({name, value: formulaValue}) => {
  // @ts-ignore
  const formula: IFormulaValue = formulas[name];
  return (
    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontFamily: 'Roboto'}}>
      {formula &&
        formula.value.map(({fontStyle, sub, sup, value, type}, index) => {
          if (!formulaValue && index === formula.value.length - 1) return null;
          if (type === 'fraction') {
            const fractionValues = value as IFormulaItem[][];
            return (
              <View style={{flexDirection: 'column'}}>
                {fractionValues.map((fractionValue, fractionValueIndex) => (
                  <Fragment key={fractionValueIndex}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}>
                      {fractionValue.map(({value, fontStyle, sub, sup}, index) => (
                        <View key={index} style={{flexDirection: 'row'}}>
                          <FormulaElement key={index} value={String(value)} fontStyle={fontStyle} sub={sub} sup={sup} />
                        </View>
                      ))}
                    </View>
                    {fractionValueIndex === 0 && <View style={{borderBottomWidth: 1, borderBottomColor: '#222'}} />}
                  </Fragment>
                ))}
              </View>
            );
          }
          return <FormulaElement key={index} value={String(value)} fontStyle={fontStyle} sub={sub} sup={sup} />;
        })}
      {formulaValue && <Text>{formulaValue || '0'}</Text>}
      {formulaValue && <Text>{formula?.unit || ''}</Text>}
    </View>
  );
};

export default Formula;
