import React from 'react';
import {StyleSheet, View} from '@react-pdf/renderer';

interface ILineProps {
  color: string;
}

const styles = StyleSheet.create({
  LineWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 10,
    height: 10,
  },
  Line: {
    width: '100%',
    height: 2,
  },
});

const Line: React.FC<ILineProps> = props => {
  return (
    <View style={styles.LineWrapper}>
      <View style={{...styles.Line, backgroundColor: props.color}} />
    </View>
  );
};

export default Line;
