import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

interface IContentProps {}

const styles = StyleSheet.create({
  content: {
    justifyContent: 'flex-start',
    padding: 10,
  },
});

const Content: React.FC<IContentProps> = props => {
  return <View style={styles.content}>{props.children}</View>;
};

export default Content;
