import React, {useContext} from 'react';
import {Document, Image, Page, View} from '@react-pdf/renderer';
import {globalStyles} from '~src/components/pdf/style';
import Footer from '~src/components/pdf/Footer';
import Content from '~src/components/pdf/Content';
import PageView from '~src/components/pdf/PageView';
import Title from '~src/components/pdf/Title';
import {LocaleContext} from '~src/context';
import UserValues from '~src/components/pdf/Calculation1/UserValues';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import Section from '~src/components/pdf/Section';
import Spacer from '~src/components/pdf/Spacer';
import Legend from '~src/components/pdf/Legend';
import Conclusions1 from '~src/components/pdf/Calculation1/Conclusions1';
import {IResultValues} from '~src/services/Calculation1Service';
import Literature from '~src/components/pdf/Literature';

interface IResult1Props {
  userValues: IUserValuesAtCalculation1;
  resultValues: IResultValues;
}

const Result1: React.FC<IResult1Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  if (!props.resultValues) return null;
  return (
    <Document title={'PDF отчёт'}>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{result.title}</Title>
            <View>
              <Image style={{width: 300}} src={props.resultValues.image} />
            </View>
            <Spacer height={10} />
            <View>
              <Legend resultType={0} layers={props.userValues.layers} />
            </View>
            <Spacer height={15} />
            <Title level={2}>{result.userValuesTitle}</Title>
            <UserValues userValues={props.userValues} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>{result.sectionsTitle}</Title>
            <Section section={result.section1} resultValues={props.resultValues} />
            <Section section={result.section2} resultValues={props.resultValues} />
            <Section section={result.section3} resultValues={props.resultValues} />
            <Spacer height={15} />
            <Title level={2}>Выводы</Title>
            <Conclusions1 resultValues={props.resultValues} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>{'Литература'}</Title>
            <Literature />
          </Content>
          <Footer />
        </PageView>
      </Page>
    </Document>
  );
};

export default Result1;
