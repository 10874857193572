import React from 'react';
import {StyleSheet, View} from '@react-pdf/renderer';

interface IFooterProps {}

const styles = StyleSheet.create({
  footer: {
    justifyContent: 'space-between',
    borderTopWidth: 2,
    borderColor: '#222222',
    width: '100%',
    flexDirection: 'row',
  },
  leftSide: {
    borderColor: '#222',
    borderRightWidth: 2,
    flex: 4,
  },
  leftSideRow: {
    height: 15,
    flex: 1,
    borderBottomWidth: 1,
    borderColor: '#222',
    display: 'flex',
    flexDirection: 'row',
  },
  leftSideRowWithTopBorder: {
    borderTopWidth: 1,
  },
  leftSideRowCell1: {flex: 0.08, borderRightWidth: 1, borderColor: '#222'},
  leftSideRowCell1WithoutRightBorder: {flex: 0.08, borderRightWidth: 1, borderColor: '#FFF'},
  leftSideRowCell2: {flex: 0.175, borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#222'},
  leftSideRowCell2WithoutLeftBorder: {
    flex: 0.175,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#FFF',
    borderRightColor: '#222',
  },
  leftSideRowCell3: {flex: 0.38, borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#222'},
  leftSideRowCell4: {flex: 0.28, borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#222'},
  leftSideRowCell5: {flex: 0.175, borderLeftWidth: 1, borderColor: '#222', borderBottomColor: '#FFF'},
  rightSide: {flex: 6, flexDirection: 'column'},
  rightSideTop: {height: 30, borderBottomWidth: 1, borderColor: '#222'},
  rightSideMiddle: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 60,
    borderColor: '#222',
    flexDirection: 'row',
    display: 'flex',
  },
  rightSideBottom: {borderTopWidth: 1, height: 30, borderColor: '#222', flexDirection: 'row', display: 'flex'},
  rightSideBottomColumn1: {flex: 0.58, borderRightWidth: 2, borderRightColor: '#222', height: '100%'},
  rightSideBottomColumn2: {flex: 0.42, height: '100%'},
  rightSideTable: {
    display: 'flex',
    height: 50,
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderColor: '#222',
  },
  rightSideTableFooter: {
    display: 'flex',
    height: 10,
    flexDirection: 'row',
  },
  rightSideTableFooterCell: {
    flex: 1,
  },
  rightSideTableFooterCellLeft: {
    flex: 1,
    borderRightWidth: 2,
    borderColor: '#222',
  },
  rightSideTableColumn: {
    flex: 0.33,
  },
  rightSideTableColumnHeader: {
    height: 15,
    borderBottomWidth: 2,
    borderColor: '#222',
  },
  rightSideTableColumn1: {
    borderRightWidth: 2,
    borderColor: '#222',
  },
  rightSideTableColumn2: {
    borderRightWidth: 2,
    borderColor: '#222',
  },
});

const Footer: React.FC<IFooterProps> = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={styles.leftSide}>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1} />
          <View style={styles.leftSideRowCell2} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1} />
          <View style={styles.leftSideRowCell2} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1} />
          <View style={styles.leftSideRowCell2} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={[styles.leftSideRow, styles.leftSideRow, styles.leftSideRowWithTopBorder]}>
          <View style={styles.leftSideRow}>
            <View style={styles.leftSideRowCell1WithoutRightBorder} />
            <View style={styles.leftSideRowCell2WithoutLeftBorder} />
            <View style={styles.leftSideRowCell3} />
            <View style={styles.leftSideRowCell4} />
            <View style={styles.leftSideRowCell5} />
          </View>
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1WithoutRightBorder} />
          <View style={styles.leftSideRowCell2WithoutLeftBorder} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1WithoutRightBorder} />
          <View style={styles.leftSideRowCell2WithoutLeftBorder} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1WithoutRightBorder} />
          <View style={styles.leftSideRowCell2WithoutLeftBorder} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
        <View style={styles.leftSideRow}>
          <View style={styles.leftSideRowCell1WithoutRightBorder} />
          <View style={styles.leftSideRowCell2WithoutLeftBorder} />
          <View style={styles.leftSideRowCell3} />
          <View style={styles.leftSideRowCell4} />
          <View style={styles.leftSideRowCell5} />
        </View>
      </View>
      <View style={styles.rightSide}>
        <View style={styles.rightSideTop} />
        <View style={styles.rightSideMiddle}>
          <View style={styles.rightSideBottomColumn1} />
          <View style={styles.rightSideBottomColumn2}>
            <View style={styles.rightSideTable}>
              <View style={[styles.rightSideTableColumn, styles.rightSideTableColumn1]}>
                <View style={styles.rightSideTableColumnHeader} />
              </View>
              <View style={[styles.rightSideTableColumn, styles.rightSideTableColumn2]}>
                <View style={styles.rightSideTableColumnHeader} />
              </View>
              <View style={styles.rightSideTableColumn}>
                <View style={styles.rightSideTableColumnHeader} />
              </View>
            </View>
            <View style={styles.rightSideTableFooter}>
              <View style={[styles.rightSideTableFooterCell, styles.rightSideTableFooterCellLeft]} />
              <View style={styles.rightSideTableFooterCell} />
            </View>
          </View>
        </View>
        <View style={styles.rightSideBottom}>
          <View style={styles.rightSideBottomColumn1} />
          <View style={styles.rightSideBottomColumn2} />
        </View>
      </View>
    </View>
  );
};

export default Footer;
