import React from 'react';
import {StyleSheet, View} from '@react-pdf/renderer';
import {customColors} from '~src/theme/colors';

interface ILayerProps {}

const styles = StyleSheet.create({
  Layer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: customColors.blue700,
    color: '#FFF',
    fontSize: 6,
    width: 10,
    height: 10,
  },
});

const Layer: React.FC<ILayerProps> = (props) => {
  return <View style={styles.Layer}>{props.children}</View>;
};

export default Layer;
