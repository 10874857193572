import {StyleSheet} from '@react-pdf/renderer';

export const globalStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
    paddingLeft: 50,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
});
