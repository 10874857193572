import React, {useContext} from 'react';
import Line from './Line';
import Layer from './Layer';
import {ILayer} from '~src/services/Calculation1Service';
import Zone from './Zone';
import {LocaleContext} from '~src/context';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import Spacer from '~src/components/pdf/Spacer';

const styles = StyleSheet.create({
  Row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  AxisLabel: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 10,
  },
});

interface ILegendProps {
  resultType: 0 | 1 | 2;
  layers?: ILayer[];
}

const Legend: React.FC<ILegendProps> = props => {
  const {
    pages: {
      calculation1: {
        result: {chartsLegend, legendAxis},
      },
    },
  } = useContext(LocaleContext);

  const values = chartsLegend[props.resultType];
  return (
    <View>
      {values.map((value, index) => (
        <View key={index} style={styles.Row}>
          {value.type === 'zone' ? <Zone /> : <Line color={value.color} />}
          <Spacer width={5} />
          <View>
            <Text>{value.label}</Text>
          </View>
        </View>
      ))}
      {props.layers &&
        props.layers.map((layer, index) => (
          <View key={index} style={styles.Row}>
            <Layer>
              <Text>{layer.layerId}</Text>
            </Layer>
            <Spacer width={5} />
            <View>
              <Text>{layer.layerName}</Text>
            </View>
          </View>
        ))}
      <View style={styles.Row}>
        <View style={styles.AxisLabel}>
          <Text>X</Text>
        </View>
        <Spacer width={5} />
        <View>
          <Text>{legendAxis[props.resultType].x}</Text>
        </View>
      </View>
      <View style={styles.Row}>
        <View style={styles.AxisLabel}>
          <Text>Y</Text>
        </View>
        <Spacer width={5} />
        <View>
          <Text>{legendAxis[props.resultType].y}</Text>
        </View>
      </View>
    </View>
  );
};

export default Legend;
