import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import Formula from '~src/components/pdf/Formula';
import {IResultValues} from '~src/services/Calculation1Service';

interface ISectionProps {
  section: {
    title: string;
    rows: IRow[];
  };
  resultValues: IResultValues;
}

interface IRow {
  name: keyof IResultValues;
  title?: string;
}

const styles = StyleSheet.create({
  Section: {paddingBottom: 15},
  Title: {paddingBottom: 10},
  Main: {borderBottomWidth: 1, borderColor: '#e8e8e8', borderTopWidth: 1},
  Row: {paddingLeft: 10},
  RowTitle: {paddingBottom: 3, fontSize: 8, color: '#999999'},
});

const Section: React.FC<ISectionProps> = props => {
  return (
    <View style={styles.Section}>
      <Text style={styles.Title}>{props.section.title}</Text>
      <View style={styles.Main}>
        {props.section.rows.map((row: IRow, index: number) => {
          return (
            <View key={index} style={{...styles.Row, paddingBottom: index === props.section.rows.length - 1 ? 3 : 10}}>
              <Text style={styles.RowTitle}>{row.title}</Text>
              <View>
                <Formula name={row.name as any} value={props.resultValues[row.name]} />
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Section;
