import React from 'react';
import {StyleSheet, View} from '@react-pdf/renderer';

interface IPageViewProps {}

const styles = StyleSheet.create({
  view: {
    borderWidth: 2,
    borderColor: '#222222',
    flex: 1,
    justifyContent: 'space-between',
  },
});

const PageView: React.FC<IPageViewProps> = props => {
  return <View style={styles.view}>{props.children}</View>;
};

export default PageView;
