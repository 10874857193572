import React, {Fragment, useContext} from 'react';
import {Document, Page, View, Text, StyleSheet, Image} from '@react-pdf/renderer';
import {globalStyles} from '~src/components/pdf/style';
import Footer from '~src/components/pdf/Footer';
import Content from '~src/components/pdf/Content';
import PageView from '~src/components/pdf/PageView';
import Title from '~src/components/pdf/Title';
import {LocaleContext} from '~src/context';
import UserValues from '~src/components/pdf/Calculation1/UserValues';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import Spacer from '~src/components/pdf/Spacer';
import Legend from '~src/components/pdf/Legend';
import {IResultValues} from '~src/services/Calculation1Service';
import Literature from '~src/components/pdf/Literature';
import Formula from '~src/components/pdf/Formula';
import Conclusions2 from '~src/components/pdf/Calculation1/Conclusions2';
import Table from '~src/components/pdf/Table';
import {createDataSourceResult2} from '~src/utils/calculations';

interface Props {
  userValues: IUserValuesAtCalculation1;
  resultValues: IResultValues;
}

const styles = StyleSheet.create({
  RowTitle: {paddingBottom: 3, fontSize: 8, color: '#999999'},
});

const Result2: React.FC<Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  if (!props.resultValues || !props.userValues) return null;
  const dataSource = createDataSourceResult2(props.userValues, props.resultValues);
  return (
    <Document title={'PDF отчёт'}>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{result.title}</Title>
            <View>
              <Image style={{width: 300}} src={props.resultValues.image} />
            </View>
            <Spacer height={10} />
            <View>
              <Legend resultType={1} layers={(props.userValues as IUserValuesAtCalculation1).layers} />
            </View>
            <Spacer height={15} />
            <Title level={2}>{result.userValuesTitle}</Title>
            <UserValues userValues={props.userValues as IUserValuesAtCalculation1} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>{result.sectionsTitle}</Title>
            <Title level={3}>1. Вычисление зоны максимального увлажнения</Title>
            <View>
              <Text style={styles.RowTitle}>
                Для определения плоскости возможной конденсации определим для каждого слоя значение комлекса fi(tм.у.)
                cогласно СП 50.13330.2012 по формуле (8.7)
              </Text>
              <Formula name={'f_i'} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определим общее сопротивление паропроницаемости ограждающей конструкции
              </Text>
              <Formula name={'r_op'} value={props.resultValues.r_op} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>Определяем парциальное давление насыщенного водяного пара</Text>
              <Formula name={'E_b'} value={props.resultValues.E_b} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>Определяем парциальное давление водяного пара внутреннего воздуха</Text>
              <Formula name={'e_b'} value={props.resultValues.e_b} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определяем среднюю температуру наружного воздуха для периода с отрицательными среднемесячными
                температурами
              </Text>
              <Formula name={'t_notr'} value={props.resultValues.t_notr} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определяем среднее парциальное давление водяного пара наружного воздуха периода месяцев с отрицательными
                среднемесячными температурами
              </Text>
              <Formula name={'e_notr'} value={props.resultValues.e_notr} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>Определим координаты плоскости максимального увлажнения</Text>
              <Formula name={'x_mu'} value={props.resultValues.x_mu} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определим паропроницаемость ограждающей конструкции (в пределах от внутренней поверхности до плоскости
                возможной конденсации)
              </Text>
              <Formula name={'R_ni'} value={props.resultValues.R_ni} />
            </View>
            <Spacer height={5} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <View>
              <Table dataSource={dataSource} columns={result.result2TableGeneration.heatLossParams.columns} />
            </View>
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={3}>2. Возможность накопления влаги за годовой период эксплуатации</Title>
            <View>
              <Text style={styles.RowTitle}>
                Определяем Е — парциальное давление водяного пара, в плоскости возможной конденсации за годовой период
                эксплуатации
              </Text>
              <Formula name={'E'} value={props.resultValues.E} />
              <Text>
                E1, Е2, Е3 - парциальные давления водяного пара, Па, принимаемые по температуре ti, в плоскости
                возможной конденсации, определяемой при средней температуре наружного воздуха соответственно зимнего,
                весенне-осеннего и летнего периодов;
              </Text>
              <Text>
                z1, z2, z3, - продолжительность, мес, соответственно зимнего, весенне-осеннего и летнего периодов,
                определяемая с учетом следующих условий:
              </Text>
              <Text>
                а) к зимнему периоду относятся месяцы со средними температурами наружного воздуха ниже минус 5 °С;
              </Text>
              <Text>
                б) к весенне-осеннему периоду относятся месяцы со средними температурами наружного воздуха от минус 5 до
                плюс 5 °С;
              </Text>
              <Text>
                в) к летнему периоду относятся месяцы со средними температурами наружного воздуха выше плюс 5 °С.
              </Text>
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Для определения ti определим ∑R-термическое сопротивление слоя ограждения в пределах от внутренней
                поверхности до плоскости возможной конденсации:
              </Text>
              <Formula name={'termResist'} value={props.resultValues.termResist} />
            </View>
            <Spacer height={5} />
            <View>
              <Text>{`2.1. Зимний период${
                props.resultValues.z_n![0].length === 0 ? result.result2.emptyZLabel : ''
              }`}</Text>
              {props.resultValues.z_n![0].length > 0 && (
                <Fragment>
                  <Formula name={'z1'} value={props.resultValues.z_n![0].length} />
                  <Formula name={'to1'} value={props.resultValues.t_on![0]} />
                  <Formula name={'t1'} value={props.resultValues.t_n![0]} />
                  <Formula name={'E1'} value={props.resultValues.E_n![0]} />
                </Fragment>
              )}
            </View>
            <Spacer height={5} />
            <View>
              <Text>{`2.2. Весенне-осенний период${
                props.resultValues.z_n![1].length === 0 ? result.result2.emptyZLabel : ''
              }`}</Text>
              {props.resultValues.z_n![1].length > 0 && (
                <Fragment>
                  <Formula name={'z2'} value={props.resultValues.z_n![1].length} />
                  <Formula name={'to2'} value={props.resultValues.t_on![1]} />
                  <Formula name={'t2'} value={props.resultValues.t_n![1]} />
                  <Formula name={'E2'} value={props.resultValues.E_n![1]} />
                </Fragment>
              )}
            </View>
            <Spacer height={5} />
            <View>
              <Text>{`2.3. Летний период${
                props.resultValues.z_n![2].length === 0 ? result.result2.emptyZLabel : ''
              }`}</Text>
              {props.resultValues.z_n![2].length > 0 && (
                <Fragment>
                  <Formula name={'z3'} value={props.resultValues.z_n![2].length} />
                  <Formula name={'to3'} value={props.resultValues.t_on![2]} />
                  <Formula name={'t3'} value={props.resultValues.t_n![2]} />
                  <Formula name={'E3'} value={props.resultValues.E_n![2]} />
                </Fragment>
              )}
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определим парциальное давление водяного пара в плоскости возможной конденсации за годовой период
                эксплуатации ограждающей конструкции для соответствующих продолжительностей периодов z1, z2, z3:
              </Text>
              <Formula name={'E'} value={props.resultValues.E} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Определим общее сопровтивление паропроницаемости ограждающей конструкции
              </Text>
              <Formula name={'R_pn'} value={props.resultValues.R_pn} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Расчёт требуемого сопротивления паропроницанию (из условия недопустимости накопления влаги в ограждающей
                конструкции за годовой период эксплуатации)
              </Text>
              <Formula name={'R_tr_n1'} value={props.resultValues.R_tr_n1} />
            </View>
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>
              3. Возможность накопления влаги за период с отрицательными среднемесячными температурами
            </Title>
            <View>
              <Text style={styles.RowTitle}>
                Для расчета нормируемого сопротивления паропроницанию Rn2^тр из условия ограничения влаги за период с
                отрицательными средними месячными температурами наружного воздуха берем определенную по таблице 5.1 СП
                131.13330.2018 продолжительность этого периода z0, среднюю температуру этого периода t0:
              </Text>
              <Formula name={'z_o'} value={(props.userValues as IUserValuesAtCalculation1).z0} />
              <Formula name={'t_o'} value={(props.userValues as IUserValuesAtCalculation1).t0} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Температуру t_0, в плоскости возможной конденсации для этого периода определяют по формуле (8.10) СП
                50.13330.2012
              </Text>
              <Formula name={'t_o'} value={props.resultValues.t_o1} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Парциальное давление водяного пара Е0 в плоскости возможной конденсации определяют по формуле (8.8) СП
                50.13330.2012
              </Text>
              <Formula name={'E_o'} value={props.resultValues.E_o} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>Коэффициент η определяется по формуле (8.5) СП 50.13330.2012</Text>
              <Formula name={'eta'} value={props.resultValues.eta} />
            </View>
            <Spacer height={5} />
            <View>
              <Text style={styles.RowTitle}>
                Делаем расчет требуемого сопротивления паропроницанию (из условия ограничения влаги в ограждающей
                конструкции за период с отрицательными средними месячными температурами наружного воздуха)
              </Text>
              <Formula name={'R_tr_n2'} value={String(props.resultValues.R_tr_n2)} />
            </View>
            <Spacer height={10} />
            <Title level={2}>Выводы</Title>
            <Text style={styles.RowTitle}>
              Сопротивление паропроницанию конструкции стены в пределах от внутренней поверхности до плоскости возможной
              конденсации
            </Text>
            <Conclusions2 resultValues={props.resultValues} />
          </Content>
          <Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={2}>{'Литература'}</Title>
            <Literature />
          </Content>
          <Footer />
        </PageView>
      </Page>
    </Document>
  );
};

export default Result2;
