import React, {useContext} from 'react';
import {Text, View} from '@react-pdf/renderer';
import {LocaleContext} from '~src/context';

interface Props {}

const Literature: React.FC<Props> = () => {
  const {pages} = useContext(LocaleContext);

  return (
    <View>
      {pages.calculation1.result.literature.map((item, index) => (
        <View key={index}>
          <Text>- {item}</Text>
        </View>
      ))}
    </View>
  );
};

export default Literature;
