import React, {useContext} from 'react';
import {Text, View} from '@react-pdf/renderer';
import {getUserValuesByCalculation1} from '~src/utils/calculations';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {LocaleContext} from '~src/context';

interface IUserValuesProps {
  userValues: IUserValuesAtCalculation1;
}

const UserValues: React.FC<IUserValuesProps> = props => {
  const {pages} = useContext(LocaleContext);
  const dataSource = getUserValuesByCalculation1(props.userValues, pages.calculation1);

  return (
    <View>
      {dataSource.map((value, index) => (
        <View key={index} style={{flexDirection: 'row'}}>
          <Text>{value.title}</Text>
          <Text>&nbsp;—&nbsp;</Text>
          <Text>{value.value}</Text>
        </View>
      ))}
    </View>
  );
};

export default UserValues;
