import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

interface ISectionProps {
  columns: IColumn[];
  tableTitle?: string;
  dataSource: any[];
}

interface IColumn {
  dataIndex: string;
  columnTitle?: string;
  columnWidth?: {
    width: string;
  };
}

const styles = StyleSheet.create({
  Section: {paddingBottom: 15},
  Title: {paddingBottom: 10, fontWeight: 'extrabold', fontSize: 12},
  Main: {borderBottomWidth: 1, borderColor: '#e8e8e8', borderTopWidth: 1},
  Row: {paddingLeft: 10},
  RowTitle: {paddingBottom: 3, fontSize: 8, color: '#999999'},
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#f0f0f0',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableHeaderRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#f0f0f0',
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    fontSize: 8,
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 'heavy',
    margin: 'auto',
    marginLeft: 5,
  },
});

const Section: React.FC<ISectionProps> = props => {
  return (
    <View style={styles.Section}>
      <Text style={styles.Title}>{props.tableTitle}</Text>
      <View style={styles.Main}>
        <View style={styles.table}>
          {props.columns.some(item => item.columnTitle) && (
            <View style={styles.tableHeaderRow}>
              {props.columns.map((column: IColumn, index: number) => {
                return (
                  <View style={[styles.tableCol, column.columnWidth as any]} key={index}>
                    <Text key={index + '_header'} style={styles.tableHeader}>
                      {column.columnTitle}
                    </Text>
                  </View>
                );
              })}
            </View>
          )}
          {props.dataSource.map((item, index: number) => {
            return (
              <View style={styles.tableRow} key={index}>
                {props.columns.map((column: IColumn, innerIndex: number) => {
                  return (
                    <View style={[styles.tableCol, column.columnWidth as any]} key={innerIndex}>
                      {item.name !== null ? (
                        <View style={[styles.tableCell, {marginRight: innerIndex === 0 ? 15 : 5}]}>
                          <Text>{item[column.dataIndex]}</Text>
                        </View>
                      ) : null}
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default Section;
