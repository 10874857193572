import React, {useContext, useEffect, useRef, useState} from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import qs from 'qs';

import {Row, Col, Typography} from 'antd';
import Providers from '~src/components/Providers';
import {ContentView, Modals, PageTemplate} from '~src/components';
import {
  PDFCalculation1Result1,
  PDFCalculation1Result2,
  PDFCalculation1Result3,
  PDFCalculation2Result1,
  PDFCalculation2Result2,
} from '~src/components/pdf';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {IUserValuesAtCalculation2} from '~src/store/Calculations';
import {IUserValuesAtCalculation3} from '~src/store/Calculations';
import {IResultValues} from '~src/services/Calculation1Service';
import {IResultValues as ICalc2ResultValues} from '~src/services/Calculation2Service';
import {IResultValues as ICalc3ResultValues} from '~src/services/Calculation3Service';
import Seo from '~src/components/Seo';
const {Title} = Typography;

interface IReportProps extends IPage {}

const Report: React.FC<IReportProps> = ({location, navigate, path}) => {
  const {
    pages: {report},
  } = useContext(LocaleContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const {dispatch, stats} = useStoreon<IState, IEvents>('calculations', 'stats');
  const [isClient, setIsClient] = useState(false);
  const ref = useRef<any>(null);

  if (ref.current && !isDownload) {
    ref.current.click();
    setIsDownload(true);
  }

  useEffect(() => {
    setIsClient(true);
    const query = window.location.search.replace('?', '');
    const {calculation_id} = qs.parse(query);
    if (!calculation_id) return;
    dispatch('stats/fetch-calculation', {
      calculationId: calculation_id as string,
    });
  }, []);

  return (
    <Providers>
      <Seo title={report.meta.title} />
      <PageTemplate>
        <ContentView>
          <MainStyled gutter={[50, 20]}>
            <Col span={24}>
              <Title level={1}>{report.header1}</Title>
            </Col>
            <Col span={24}>
              <Typography.Paragraph>
                Если файл не скачался автоматически, то нажмите кнопку. Спасибо!
              </Typography.Paragraph>
              {stats.calculation.userValues && stats.calculation.resultValues && isClient && (
                <PDFDownloadLink
                  document={
                    (stats.calculation.resultValues.calculationSubtype === 1 && (
                      <PDFCalculation1Result1
                        userValues={stats.calculation.userValues as IUserValuesAtCalculation1}
                        resultValues={stats.calculation.resultValues as IResultValues}
                      />
                    )) ||
                    (stats.calculation.resultValues.calculationSubtype === 2 && (
                      <PDFCalculation1Result2
                        userValues={stats.calculation.userValues as IUserValuesAtCalculation1}
                        resultValues={stats.calculation.resultValues as IResultValues}
                      />
                    )) ||
                    (stats.calculation.resultValues.calculationSubtype === 3 && (
                      <PDFCalculation1Result3
                        userValues={stats.calculation.userValues as IUserValuesAtCalculation1}
                        resultValues={stats.calculation.resultValues as IResultValues}
                      />
                    )) ||
                    (stats.calculation.resultValues.calculationSubtype === 4 && (
                      <PDFCalculation2Result1
                        userValues={stats.calculation.userValues as IUserValuesAtCalculation2}
                        resultValues={stats.calculation.resultValues as ICalc2ResultValues}
                      />
                    )) ||
                    (stats.calculation.resultValues.calculationSubtype === 5 && (
                      <PDFCalculation2Result2
                        userValues={stats.calculation.userValues as IUserValuesAtCalculation3}
                        resultValues={stats.calculation.resultValues as ICalc3ResultValues}
                      />
                    ))
                  }
                  fileName={`${Date.now()}.pdf`}
                >
                  {({blob, url, loading, error}) => {
                    if (!loading && !error && url && !isLoaded) {
                      setIsLoaded(true);
                    }
                    return (
                      <button disabled={loading} ref={ref}>
                        {loading ? 'Загрузка' : 'Скачать отчёт'}
                      </button>
                    );
                  }}
                </PDFDownloadLink>
              )}
            </Col>
          </MainStyled>
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

const MainStyled = styled(Row)`
  padding-top: 50px;
`;

export default Report;
