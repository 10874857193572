import React, {useContext} from 'react';
import {Document, Page, Image, StyleSheet, View, Text} from '@react-pdf/renderer';
import {globalStyles} from '~src/components/pdf/style';
import Content from '~src/components/pdf/Content';
import PageView from '~src/components/pdf/PageView';
import Title from '~src/components/pdf/Title';
import {LocaleContext} from '~src/context';
import {IUserValuesAtCalculation2} from '~src/store/Calculations';
import Table from '~src/components/pdf/Table';
import Spacer from '~src/components/pdf/Spacer';
import {IResultValues} from '~src/services/Calculation2Service';
import Calc2Footer from '~src/components/pdf/Calc2Footer';

interface IResult1Props {
  userValues: IUserValuesAtCalculation2;
  resultValues: IResultValues;
}

const Result1: React.FC<IResult1Props> = props => {
  const {
    pages: {
      calculation2: {result},
    },
  } = useContext(LocaleContext);
  if (!props.resultValues) return null;

  const passResultValuesToDataSource = (dataSource: any[]) => {
    return dataSource.map(item => {
      if (props.resultValues[item.name] !== 0 || props.resultValues[item.name] !== null) {
        return {...item, name: props.resultValues[item.name]};
      } else {
        return {...item, name: null};
      }
    });
  };

  const determineShowTrim = () => {
    if (props.userValues.enabledFlags.find(item => item === 'trim')) {
      props.userValues.trims[0].trimType === 'Штукатурка для внутренних работ' ? (
        <Table
          columns={result.section3Plaster.columns}
          dataSource={passResultValuesToDataSource(result.section3Plaster.rows)}
          tableTitle={result.section3Plaster.title}
        />
      ) : (
        <Table
          columns={result.section3Gkl.columns}
          dataSource={passResultValuesToDataSource(result.section3Gkl.rows)}
          tableTitle={result.section3Gkl.title}
        />
      );
    }
  };

  const renderedImageDescription = props.userValues.enabledFlags.find(item => item === 'insulationPanel')
    ? [
        {
          index: 1,
          title: props.userValues.bearingWalls[0].brickType,
          property: props.userValues.bearingWalls[0].masonryOption + ' блока',
        },
        {
          index: 2,
          title: props.userValues.insulation[0].insulationType,
          property: props.userValues.insulation[0].insulationThickness + ' мм',
        },
        {
          index: 3,
          title: props.userValues.facades[0].facadeType,
          property: props.userValues.facades[0].facadeBrickWidth + ' мм',
        },
      ]
    : [
        {
          index: 1,
          title: props.userValues.bearingWalls[0].brickType,
          property: props.userValues.bearingWalls[0].masonryOption + ' блока',
        },
        {
          index: 2,
          title: props.userValues.facades[0].facadeType,
          property: props.userValues.facades[0].facadeBrickWidth + ' мм',
        },
      ];

  const styles = StyleSheet.create({
    bearingWallImage: {
      width: 250,
      zIndex: 3,
      position: 'absolute',
    },
    facadeImage: {
      width: 250,
      zIndex: 1,
      position: 'absolute',
    },
    insulationImage: {
      width: 250,
      zIndex: 2,
      position: 'absolute',
    },
    imageBox: {
      height: 200,
      width: 250,
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    imageDescription: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginTop: 20,
      marginRight: 50,
    },
    imageDescriptionText: {
      fontSize: 7,
    },
  });

  return (
    <Document title={'PDF отчёт'}>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            <Title level={1}>{result.sectionsTitle}</Title>
            <View style={styles.imageContainer}>
              <View style={styles.imageBox}>
                <Image
                  style={styles.bearingWallImage}
                  src={props.userValues.bearingWallImage ? props.userValues.bearingWallImage : ''}
                />
                <Image
                  style={styles.facadeImage}
                  src={props.userValues.facadeImage ? props.userValues.facadeImage : ''}
                />
                <Image
                  style={styles.insulationImage}
                  src={props.userValues.insulationImage ? props.userValues.insulationImage : ''}
                />
              </View>
              <View style={styles.imageDescription}>
                {renderedImageDescription.map((item, index) => {
                  return (
                    <Text key={index} style={styles.imageDescriptionText}>
                      {item.index}. {item.title}, {item.property}
                    </Text>
                  );
                })}
              </View>
            </View>
            <Table
              columns={result.section1.columns}
              dataSource={passResultValuesToDataSource(result.section1.rows)}
              tableTitle={result.section1.title + props.userValues.bearingWalls[0].brickType}
            />
            <Table
              columns={result.sectionInsulation.columns}
              dataSource={passResultValuesToDataSource(result.sectionInsulation.rows)}
              tableTitle={result.sectionInsulation.title + props.userValues.insulation[0].insulationType}
            />
            <Spacer height={15} />
          </Content>
          <Calc2Footer />
        </PageView>
      </Page>
      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            {props.userValues.facades[0].facadeType === 'Фасадная штукатурка' ? (
              <Table
                columns={result.section2Plaster.columns}
                dataSource={passResultValuesToDataSource(result.section2Plaster.rows)}
                tableTitle={result.section2Plaster.title + props.userValues.facades[0].facadeType}
              />
            ) : props.userValues.facades[0].facadeType === 'Вентилируемый фасад' ||
              props.userValues.facades[0].facadeType === 'Сайдинг' ||
              props.userValues.facades[0].facadeType === 'Фасадные панели' ? (
              <Table
                columns={result.section2Panel.columns}
                dataSource={passResultValuesToDataSource(result.section2Panel.rows)}
                tableTitle={result.section2Panel.title + props.userValues.facades[0].facadeType}
              />
            ) : (
              <Table
                columns={result.section2.columns}
                dataSource={passResultValuesToDataSource(result.section2.rows)}
                tableTitle={result.section2.title + props.userValues.facades[0].facadeType}
              />
            )}
            {props.userValues.enabledFlags.find(item => item === 'partitions') ? (
              <Table
                columns={result.section4.columns}
                dataSource={passResultValuesToDataSource(result.section4.rows)}
                tableTitle={result.section4.title + props.userValues.partitions[0].partitionBrickType}
              />
            ) : null}
            <Spacer height={15} />
          </Content>
          <Calc2Footer />
        </PageView>
      </Page>

      <Page size="A4" style={globalStyles.page} wrap>
        <PageView>
          <Content>
            {determineShowTrim()}
            {props.userValues.enabledFlags.find(item => item === 'windows' || item === 'doors') ||
            props.userValues.enabledSubFlags.find(item => item === 'partitionDoors' || item === 'partitionWindows') ? (
              <Table
                columns={result.section5.columns}
                dataSource={passResultValuesToDataSource(result.section5.rows)}
                tableTitle={result.section5.title}
              />
            ) : null}
            <Table
              columns={result.section6.columns}
              dataSource={passResultValuesToDataSource(result.section6.rows)}
              tableTitle={result.section6.title}
            />
            <Spacer height={15} />
          </Content>
          <Calc2Footer />
        </PageView>
      </Page>
    </Document>
  );
};

export default Result1;
