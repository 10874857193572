import {Font} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

export {default as PDFCalculation1Result1} from './Calculation1/Result1';
export {default as PDFCalculation1Result2} from './Calculation1/Result2';
export {default as PDFCalculation1Result3} from './Calculation1/Result3';
export {default as PDFCalculation2Result1} from './Calculation2/Result1';
export {default as PDFCalculation2Result2} from './Calculation2/Result2';
