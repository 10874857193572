import React from 'react';
import {StyleSheet, View, Font, Text} from '@react-pdf/renderer';

import gostTypeB from '~src/assets/fonts/gostTypeB.ttf';
import inter from '~src/assets/fonts/interBold.ttf';

interface IFooterProps {}

Font.register({
  family: 'Gost Type B',
  fonts: [
    {
      src: gostTypeB,
    },
  ],
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: inter,
    },
  ],
});

const styles = StyleSheet.create({
  footer: {
    justifyContent: 'space-between',
    borderTopWidth: 2,
    borderColor: '#222222',
    width: '100%',
    flexDirection: 'row',
  },
  leftSide: {
    borderColor: '#222',
    borderRightWidth: 2,
    flex: 7,
  },
  leftSideRow: {
    height: 45,
    flex: 1,
    // borderBottomWidth: 1,
    borderColor: '#222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  rightSide: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    // borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  leftSideFont: {
    fontFamily: 'Gost Type B',
    fontSize: 12,
    marginRight: 220,
  },
  rightSideFont: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: '#096DD9',
    textTransform: 'uppercase',
  },
});

const Calc2Footer: React.FC<IFooterProps> = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={styles.leftSide}>
        <View style={styles.leftSideRow}>
          <Text style={styles.leftSideFont}>Расчет материалов</Text>
        </View>
      </View>
      <View style={styles.rightSide}>
        <Text style={styles.rightSideFont}>расчетстен.рф</Text>
      </View>
    </View>
  );
};

export default Calc2Footer;
